var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "device-type-detail-component table-component" },
        [
          _c(
            "div",
            { staticClass: "page-body page-body-bg-white" },
            [
              _c(
                "a-tabs",
                {
                  attrs: { type: "card", "default-active-key": "1" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "详情" } },
                    [
                      _c(
                        "a-descriptions",
                        {
                          staticClass: "tab-margin-16",
                          attrs: { bordered: "" },
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "消息来源", span: 1 } },
                            [_vm._v(" " + _vm._s(_vm.model.appName) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "标题", span: 1 } },
                            [_vm._v(" " + _vm._s(_vm.model.title) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "类型", span: 1 } },
                            [_vm._v(" " + _vm._s(_vm.model.bizType) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "创建时间", span: 1 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(_vm.model.createdTime)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "发送时间", span: 1 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(_vm.model.sendTime)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "当前状态", span: 1 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.readStatusDesc) + " "
                              ),
                            ]
                          ),
                          _vm._l(_vm.model.bodies, function (item) {
                            return _c(
                              "a-descriptions-item",
                              {
                                key: item.label,
                                attrs: { label: item.label, span: 1 },
                              },
                              [_vm._v(" " + _vm._s(item.value) + " ")]
                            )
                          }),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "内容", span: 2 } },
                            [_vm._v(" " + _vm._s(_vm.model.content) + " ")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "jtl-button",
            {
              staticClass: "table-header-button",
              staticStyle: { "margin-left": "16px" },
              attrs: { type: "primary" },
              on: { click: _vm.navToPages },
            },
            [_vm._v("查看")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }