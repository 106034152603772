import { DATE_FORMAT } from '@common-src/filter/date-format';
import { PeriodTypeEnum } from '@common-src/model/enum';
import moment, { Moment } from 'moment';

// 获取去年今日--今年今日数组
export function timeRange() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    const pre = date.toISOString().split('T')[0];
    const now = new Date().toISOString().split('T')[0];
    return [pre, now];
}

export const getEndTimeByIntervalType = (sourceEndTime: Moment, intervalType: PeriodTypeEnum, format = DATE_FORMAT) => {
    if (!sourceEndTime) {
        return;
    }
    let endTime: number = null;
    switch (intervalType) {
        case PeriodTypeEnum.HOUR:
            endTime = sourceEndTime.clone().add(1, 'hour').startOf('hour').toDate().getTime();
            if (endTime < new Date().getTime()) {
                return getEndTimeByIntervalType(sourceEndTime, PeriodTypeEnum.DAY);
            }
            break;
        case PeriodTypeEnum.DAY:
            endTime = sourceEndTime.clone().add(1, 'day').startOf('day').toDate().getTime();
            break;
        case PeriodTypeEnum.WEEK:
            endTime = sourceEndTime.clone().add(1, 'week').startOf('week').toDate().getTime();
            break;
        case PeriodTypeEnum.MONTH:
            endTime = sourceEndTime.clone().add(1, 'month').startOf('month').toDate().getTime();
            break;
        // case PeriodTypeEnum.SEASON:
        //     endTime = sourceEndTime.clone().add(3, 'month').startOf('month').toDate().getTime();
        //     break;
        case PeriodTypeEnum.YEAR:
            endTime = sourceEndTime.clone().add(1, 'year').startOf('year').toDate().getTime();
            break;
        default:
            endTime = sourceEndTime.toDate().getTime();
            break;
    }
    // return endTime;
    return moment(endTime).format(format);
};
