import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { MessageCenterEntityModel, MessageCenterQueryModel } from '@/entity-model/message-center-entity';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import { IResponseFilterData } from '@common-src/interface/reponse-data.interface';

export default class MessageCenterService extends BaseCRUDQService<MessageCenterEntityModel, MessageCenterQueryModel> {
    constructor(baseUrl = MessageCenterEntityModel.baseUrl) {
        super(baseUrl, MessageCenterEntityModel);
    }

    static async getEdgeDeviceTypeList(filter?: any, page?: number, pageSize?: number):Promise<IResponseFilterData<DeviceTypeEntityModel>> {
        const url = `${MessageCenterEntityModel.baseUrl}/product/list`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<DeviceTypeEntityModel>>(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: DeviceTypeEntityModel }
        );
        return res;
    }
}
