
















































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { MessageCenterEntityModel, MessageCenterQueryModel } from '@/entity-model/message-center-entity';
import MessageCenterService from '@/service/message-center';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';
import { ModuleType } from '@common-src/enum/module-type.enum';

const messageCenterService = new MessageCenterService();

@Component
export default class EdgeDetailDrawer extends DrawerComponent {
    model: MessageCenterEntityModel = new MessageCenterEntityModel();
    tabIndex: string = '1';
    modelId: string = null;

    drawerShow(model: MessageCenterEntityModel) {
        this.drawerOpen(model.id, model.title);
        this.modelId = model.id;
        this.init();
    }

    init() {
        this.startFullScreenLoading();
        messageCenterService.retrieve(this.modelId)
            .then(res => {
                this.model = res;
            })
            .catch(error => {
                this.model = new MessageCenterEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<MessageCenterEntityModel>).dialogOpen(this.model, messageCenterService as any, ViewModeType.UPDATE);
    }
    navToPages() {
        const info = this.model;
        if (info.bizType === 'ALARM' && info.bizIdentifier) {
            this.$router.replace({ path: `/message/message-alarm-detail/${info.bizIdentifier}` });
        } else if (info.bizType === 'WORK_ORDER' && info.bizIdentifier) {
            if (JTL.moduleType === ModuleType.WORKORDER) {
                this.$router.replace({ path: `/dashboard/workorder-detail?woId=${info.bizIdentifier}&viewType=2` });
            } else {
                AppService.getAllApp().then(res => {
                    const v = _.find(res, item => item.identifier === 'app-workorder');
                    window.open(`/web/${v.identifier}/#/dashboard/workorder-detail?woId=${info.bizIdentifier}&viewType=2`);
                });
            }
        } else {
            this.$router.push({ path: `/message/message-center` });
        }
    }
}
