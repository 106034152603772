import { BaseEntityModel } from '@common-src/model/base-model';
// import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
// import { ChannelTypeEnum, ChannelTypeList, InvisibleFunc } from './channel-entity';
import { Moment } from 'moment';
// import { dateFormat } from '@common-src/filter';
import { getEndTimeByIntervalType } from '@common-src/utils/time_uitil';
import { PeriodTypeEnum } from '@common-src/model/enum';

export const ReadStatusList = [
    { name: '未读', value: 0 },
    { name: '已读', value: 1 }
];
export class MessageCenterEntityModel extends BaseEntityModel {
    static baseUrl = `${MESSAGE_BASE_REQUEST_PATH}/platform`;

    // @FormControl({
    //     label: '消息渠道',
    //     type: FormControlType.SELECT,
    //     options: ChannelTypeList,
    //     required: true,
    //     cascad: true
    // } as FormControlOptionModel)
    // channelType: ChannelTypeEnum = undefined;

    // @FormControl({
    //     label: '消息标题',
    //     type: FormControlType.TEXT,
    //     required: true
    // } as FormControlTextModel)
    title: string = undefined;

    // @FormControl({
    //     label: '内容',
    //     type: FormControlType.TEXT_AREA,
    //     required: true,
    //     invisibleFunction: InvisibleFunc(ChannelTypeEnum.DING_ROBOT)
    // } as FormControlTextModel)
    // content: string = undefined;

    // @FormControl({
    //     label: '备注',
    //     type: FormControlType.TEXT_AREA
    // } as FormControlTextModel)
    // remark: string = undefined;
    bizIdentifier: string = undefined;
    bizType: string = undefined;
    readStatus: number = undefined;
    readStatusDesc: number = undefined;
    modelStatus:string = undefined;
    summary:string = undefined;
    bodies:Array<any>= [];
    sendTime:string = undefined;
    content:string = undefined;
    appName:string = undefined;

    static getTableColumns() {
        return [
            {
                title: '消息标题',
                dataIndex: 'title',
                // scopedSlots: { customRender: 'name' },
                width: 200
            },
            {
                title: '消息内容',
                dataIndex: 'content',
                scopedSlots: { customRender: 'content' },
                width: 400
            },
            {
                title: '消息来源',
                dataIndex: 'appName',
                customRender: (text) => {
                    return text ? text : '--';
                },
                width: 150
            },
            {
                title: '发送时间',
                dataIndex: 'sendTime',
                width: 170
            },
            {
                title: '状态',
                dataIndex: 'readStatus',
                width: 100,
                scopedSlots: { customRender: 'readStatus' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 120
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        const content = this.bodies.reduce((pre, item:any) => ` ${item.label}: ${item.value}` + pre, '');
        this.content = this.summary || content || '';
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class MessageCenterQueryModel extends QueryPageModel {
    @QueryControl({
        label: '消息标题',
        type: QueryControlType.TEXT,
        span: 6
    })
    title: string = undefined;

    @QueryControl({
        label: '消息状态',
        type: QueryControlType.SELECT,
        options: ReadStatusList,
        hasAllOption: true,
        span: 6
    })
    readStatus: number = undefined;

    @QueryControl({
        label: '发送时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 8
    })
    dateRange: Array<Moment> = [];

    toService() {
        // return { params: this.content };
        const data = super.getParams();
        if (this.dateRange.length > 0) {
            // data.params.start = +this.dateRange[0];
            data.params.start = this.dateRange[0].startOf('day').valueOf();
            data.params.end = Date.parse(getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.CUSTOM) + ' 23:59:59');
        }
        data.params.readStatus = this.readStatus;
        delete data.params.dateRange;
        return data;
        // return super.getParams();
    }
}
