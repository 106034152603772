









































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { MessageCenterEntityModel, MessageCenterQueryModel } from '@/entity-model/message-center-entity';
import MessageCenterService from '@/service/message-center';
import CommonService from '@common-src/service/common';
// import { UserStoreModule } from '@common-src/store/modules/user';
import MessageDetailDrawer from './message-detail.drawer.vue';

@Component({
    components: {
        'message-detail-drawer': MessageDetailDrawer
    }
})
export default class MessageCenterListComponent extends TableDialogFormComponent<MessageCenterEntityModel, MessageCenterQueryModel> {
    MessageCenterEntityModel = MessageCenterEntityModel;
    created() {
        this.initTable({
            service: new MessageCenterService(),
            queryModel: new MessageCenterQueryModel(),
            tableColumns: MessageCenterEntityModel.getTableColumns()
        });
        this.getList();
        this.$eventHub.$on('UPDATE_MESSAGE_STATUS', this.getList);
    }
    // 全部已读取
    async clickAllReaded() {
        // if (!UserStoreModule.unReadMessagesNum) {
        //     return;
        // }
        await CommonService.toReadALLMessages({ readAll: true });
        this.$eventHub.$emit('UPDATE_MESSAGE_INFO');
        this.getList();
    }
    // 删除
    todeleteClick(record) {
        if (!record.readStatus) {
            this.$eventHub.$emit('UPDATE_MESSAGE_INFO');
        }
        this.deleteClick(record);
    }
    // 查看
    async toviewClick(record) {
        if (!record.readStatus) {
            record.readStatus = 1;
            await CommonService.toReadALLMessages({ ids: [record.id] });
            this.$eventHub.$emit('UPDATE_MESSAGE_INFO');
        }
        // this.viewClick(record);
        (this.$refs.messageDetailDrawer as any).drawerShow(record);
    }
}

